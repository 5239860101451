import type { RequestType } from "@empowerment/authenticationservice";
import { AuthenticationService } from "@empowerment/authenticationservice";
//import { TelemetryService } from "@empowerment/telemetry";

import { getHTTPResponse } from "./HttpClientUtil";
// eslint-disable-next-line  @typescript-eslint/no-restricted-imports
import type { HTTPResponse } from "../../models/IResponse";
import { appConfig } from "~/config/appConfig";
import { createGuid, getCV } from "~/utils/headerUtils";
import { SeverityLevel, TelemetryService } from "@empowerment/telemetry";
import { getFormatDateTimeWithMilliseconds } from "~/utils/telemetryUtils";
import { TelemetryServiceTrackTraceInformation } from "~/utils/telemetryUtils/telemetryUtils";
import { authScopeConfig } from "~/config/authScopeConfig";
import { msalConfig } from "~/config/msalConfig";

export const executeHTTPRequest = async (
    requestType: RequestType,
    rawUrl: string,
    cv: string | undefined,
    correlationId: string | undefined,
    body: string | undefined
): Promise<HTTPResponse> => {
    const page = "HTTPClient";
    if (cv === undefined) {
        cv = getCV();
    } //Todo: (sivad): remove hard coding
    if (correlationId === undefined) {
        correlationId = createGuid();
    }

    //TODO:(sivad) - remove hardcoding
    //admin audience
    //3PA way
    // const adminAudienceScope = "3cbaf10d-ea88-4173-9ea6-94226c6e11b4/user_impersonation"; // "/.default"
    // let tokenScope = appConfig().apixApiScope;
    // if (body !== undefined && body.toLowerCase().includes("/admin/")) {
    //     tokenScope = adminAudienceScope;
    // }
    // let token;
    // try {
    //     token = await AuthenticationService.acquireTokenForScope(tokenScope);
    // } catch (e) {
    //1pa way
    //TODO:(sivad) - remove hardcoding
    //admin audience
    //const adminAudienceScope = "3cbaf10d-ea88-4173-9ea6-94226c6e11b4/user_impersonation"; // "/.default"
    //const adminAudienceScope = "984335a6-354a-4a63-92ba-a29655553546/CPQ.UserType.Admin.Write"; //old one
    let tokenScope = appConfig().apixApiScope;
    let envMsalConfig = msalConfig();
    let envAuthScopeConfig = authScopeConfig();
    //if (body !== undefined && body.toLowerCase().includes("/admin/")) {
    //    tokenScope = adminAudienceScope;
    //}
    if (body !== undefined && correlationId.toLowerCase() === "3") {
        //that means prod
        envMsalConfig.auth.clientId = "4d407913-d847-433c-91e8-0229d43dd0a9";
        tokenScope = "9d155943-9b9f-4914-b87e-4c3de7a150fc/APIx.User";
        correlationId = createGuid();
    } else {
        envMsalConfig.auth.clientId = "28f1f1a6-2a57-41fe-b409-5187f88a02d5";
        tokenScope = "507db335-edb5-4e46-9644-8e800ccec60e/APIx.User";
        correlationId = createGuid();
    }
    envAuthScopeConfig.apixApiScope = [tokenScope];

    let token;
    try {
        TelemetryServiceTrackTraceInformation(
            `Http Execute Auth Init - env : ${correlationId} msal : ${JSON.stringify(
                envMsalConfig,
                null,
                2
            )} , endpoint scopes ${JSON.stringify(envAuthScopeConfig, null, 2)}`,
            SeverityLevel.Information,
            { response: "" }
        );

        try {
            AuthenticationService.logout;
        } catch (e) {
            TelemetryServiceTrackTraceInformation(`logout failed`, SeverityLevel.Information, {
                response: `${JSON.stringify(tokenScope, null, 2)}`,
            });
        }
        //localStorage.clear();
        await AuthenticationService.initialize({
            config: envMsalConfig,
            endpointToScopes: envAuthScopeConfig,
        });
        try {
            const userAccount = AuthenticationService.getActiveAccount();
        } catch (e) {
            TelemetryServiceTrackTraceInformation(`getActiveAccount failed`, SeverityLevel.Information, {
                response: `${JSON.stringify(tokenScope, null, 2)}`,
            });
        }

        //let userName = userAccount?.username;

        token = await AuthenticationService.acquireTokenForScope(tokenScope);
        TelemetryServiceTrackTraceInformation(`Auth token scope change to ${tokenScope}`, SeverityLevel.Information, {
            response: `${JSON.stringify(tokenScope, null, 2)}`,
        });
    } catch (e) {
        const error = e as Error;
        console.log(`getting token error${error.message}`);
        TelemetryService.trackException(error, {
            page: "executeHTTPRequest",
            url: rawUrl,
            MSCV: cv,
            correlationId: correlationId,
        });
        return {
            statusCode: 500,
            responseBody: error.message,
        };
    }

    let headers = {
        "MS-CV": cv,
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    };

    // Pass x-ms-correlation-id only when its defined
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (correlationId !== undefined) {
        headers = { ...headers, ...{ "x-ms-correlation-id": correlationId } };
    }

    //Todo: (sivad):
    const fetchTelemetryContext = {
        page: page,
        MSCV: cv,
        correlationId: correlationId,
        method: requestType,
        url: rawUrl,
        headers: JSON.stringify(headers),
        body: body,
    };
    const acquireTokenStartTime = Date.now();

    const fetchStartTime = Date.now();
    try {
        const response = await fetch(`${rawUrl}`, {
            headers: headers,
            method: `${requestType}`,
            body: body,
        });
        //Todo: (sivad):
        //if (!httpResponse.ok) {
        //throw new Error('Failed to fetch data');
        //}

        const parseResponseStartTime = Date.now();
        const duration = parseResponseStartTime - fetchStartTime; // time it takes to make fetch() call to Service
        //Todo: (sivad) capture unhandled failure
        //Todo: (sivad): Sivad
        //eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        TelemetryServiceTrackTraceInformation(
            `${page}.Request ${cv} ${requestType} ${rawUrl}`,
            SeverityLevel.Information,
            fetchTelemetryContext
        );

        let parsedResponse: HTTPResponse = await getHTTPResponse(response, fetchTelemetryContext, duration);
        //let removedbody = body?.replace(/(bearer).*?("]")/, '$1 $2')
        //alert(removedbody);
        //let foo = "bearer lajsdfljsaldfj]";
        //   let newBody = foo.replace(/(bearer).*?(])/, '$1 $2');
        //   alert(newBody);

        const res = `${JSON.stringify(parsedResponse)}`;
        TelemetryServiceTrackTraceInformation(`${page}.Response ${cv}`, SeverityLevel.Information, { response: res });

        parsedResponse = {
            ...parsedResponse,
            requestStartTimes: {
                sendRequestStartTime: -1,
                acquireTokenStartTime: acquireTokenStartTime,
                fetchStartTime: fetchStartTime,
                parseResponseStartTime: parseResponseStartTime,
                renderResponseStartTime: -1,
            },
        };
        return parsedResponse;
    } catch (e) {
        const parseResponseStartTime = Date.now();
        const error = e as Error;
        TelemetryService.trackException(error, {
            page: "executeHTTPRequest",
            url: rawUrl,
            MSCV: cv,
            correlationId: correlationId,
        });
        return {
            statusCode: 500,
            errorType: "Unknown",
            responseBody: error.message,
            requestStartTimes: {
                sendRequestStartTime: -1,
                acquireTokenStartTime: acquireTokenStartTime,
                fetchStartTime: fetchStartTime,
                parseResponseStartTime: parseResponseStartTime,
                renderResponseStartTime: -1,
            },
        };
    }
};
