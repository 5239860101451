import { RequestType } from "@empowerment/authenticationservice";
import { appConfig } from "~/config/appConfig";
import type { Collection, Items, ServiceResponse } from "~/models/Collection";
import type { Environment, Workspace } from "~/models/Environment";
import type { CreateBlobRequest, EntityRequest, FileRequest, Request, WebInvokeRequest } from "~/models/IRequest";
import type { HTTPResponse } from "~/models/IResponse";
import { executeHTTPRequest } from "~/services/HttpClient/HttpClient";
//----------------------------------------------------------------
//Index API Operations
//-------------------------------------------------------------cls

///INDEX File Operation: for addFolder/renameFolder/deleteFolder, update the index collection correctly and Update the Index file to be uploaded.
/* export const asyncUpdateIndexToRepo1 = async (updatedIndexItem: Items | undefined) => {
    try {
       //Todo: (sivad): NOTE - the returned data is stripped off with value notes and only has index structure. overwriting may get into loss of data so don't
       //set it to context.
       if (updatedIndexItem !== undefined) {await updateIndexToRepo1(updatedIndexItem);}



      //if (appDataListFromRepo !== undefined) setCollectionList(collectionList);
      /* TelemetryService.trackTrace("App Data Fetched", SeverityLevel.Information, {
          appData: JSON.stringify(appDataList),
      }); */
//  } catch (e) {
//    const error = e as Error;
//   console.error('Error fetching app data:', error.message);
// }
// };

//input items , returns collection
export const updateIndexToRepo1 = async (updatedIndexItem: Items | undefined): Promise<Collection[] | undefined> => {
    if (updatedIndexItem !== undefined) {
        const body = JSON.stringify(updatedIndexItem);
        console.log(`httpCall-body=${body}`);
        const url = `${appConfig().apixApiBase}/V1/blob/index`; //
        const httpResponse = executeHTTPRequest(RequestType.POST, url, undefined, undefined, body);

        //Todo: (sivad): check unhappy
        const jsonData = JSON.stringify((await httpResponse).responseBody);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const jsonObject = JSON.parse(jsonData);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
        const jsonBodyObject = JSON.parse(JSON.stringify(jsonObject.body));

        const indexCollection: Collection[] = jsonBodyObject as Collection[]; //Object.values(jsonBodyObject);

        return indexCollection;
    }
    return undefined;

    /*TelemetryService.trackTrace("App Data Fetched", SeverityLevel.Information, {        appData: JSON.stringify(appDataList),    }); */
    //setData(appDataList);
};

//input items , returns serviceResponse
export const updateIndexToRepo2 = async (updatedIndexItem: Items): Promise<ServiceResponse | undefined> => {
    try {
        const body = JSON.stringify(updatedIndexItem);
        console.log(`httpCall-body=${body}`);
        const url = `${appConfig().apixApiBase}/V1/blob/index`; //
        const httpResponse = executeHTTPRequest(RequestType.POST, url, undefined, undefined, body);

        //Todo: (sivad): check unhappy
        const jsonData = JSON.stringify((await httpResponse).responseBody);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const jsonObject = JSON.parse(jsonData);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
        const jsonBodyObject = JSON.parse(JSON.stringify(jsonObject.body));
        const serviceResponse: ServiceResponse = jsonBodyObject as ServiceResponse; //Object.values(jsonBodyObject);
        return serviceResponse;
        /*TelemetryService.trackTrace("App Data Fetched", SeverityLevel.Information, {        appData: JSON.stringify(appDataList),    }); */
        //setData(appDataList);
    } catch (e) {
        const error = e as Error;
        console.error(error.message);
        return undefined;
    }
};

//asyncGetIndexFromRepo needs setCollection context so done inline within components.
//Scenario : on load to paint the collection Tree with index of request files
export const getIndexFromRepo = async (): Promise<Collection[] | undefined> => {
    try {
        const url = `${appConfig().apixApiBase}/V1/blob/index`; //
        const httpResponse = executeHTTPRequest(RequestType.GET, url, undefined, undefined, undefined);

        //Todo: (sivad): check unhappy
        const jsonData = JSON.stringify((await httpResponse).responseBody);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const jsonObject = JSON.parse(jsonData);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
        const jsonBodyObject = JSON.parse(JSON.stringify(jsonObject.body));
        const indexCollection: Collection[] = jsonBodyObject as Collection[]; //Object.values(jsonBodyObject);

        return indexCollection;
        /*TelemetryService.trackTrace("App Data Fetched", SeverityLevel.Information, {        appData: JSON.stringify(appDataList),    }); */
    } catch (e) {
        const error = e as Error;
        console.error(error.message);
        return undefined;
    }
};

//----------------------------------------------------------------
//Blob API Operations
//----------------------------------------------------------------
//Scenario : File selection will get the Request blob contents
export const getBlobFromRepo = async (getBlobRequest: FileRequest): Promise<Request | undefined> => {
    const requestType = RequestType.POST;
    const url = `${appConfig().apixApiBase}/V1/blob/get`; //
    const body = JSON.stringify(getBlobRequest);

    try {
        const httpResponse = executeHTTPRequest(requestType, url, undefined, undefined, body);
        const jsonData = JSON.stringify((await httpResponse).responseBody);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const jsonObject = JSON.parse(jsonData);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
        const jsonBodyObject = JSON.parse(JSON.stringify(jsonObject.body));
        const request: Request = jsonBodyObject as Request;
        /*         const request: Request = {
            method: jsonBodyObject.method,
            url: jsonBodyObject.url,
            body: jsonBodyObject.body,
            headers: jsonBodyObject.headers,
            params: jsonBodyObject.params,
        }; */

        return request;
        //Todo: (sivad): check unhappy
        /*TelemetryService.trackTrace("App Data Fetched", SeverityLevel.Information, {        appData: JSON.stringify(appDataList),    }); */
    } catch (e) {
        const error = e as Error;
        console.error(error.message);
        return undefined;
    }
};

//saving a request will uploaded the blob,
//Scenario : Save a Request ("Create of request" will be thru Index API where index and default blob will be created)
export const upsertBlobToRepo = async (createBlobRequest: CreateBlobRequest): Promise<ServiceResponse | undefined> => {
    const requestType = RequestType.POST;
    const url = `${appConfig().apixApiBase}/V1/blob/create`; //
    const body = JSON.stringify(createBlobRequest);
    console.log(`in upsert upsertBlobToRepo-body=${body}`);
    try {
        const httpResponse = executeHTTPRequest(requestType, url, undefined, undefined, body);
        const jsonData = JSON.stringify((await httpResponse).responseBody);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const jsonObject = JSON.parse(jsonData);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
        const jsonBodyObject = JSON.parse(JSON.stringify(jsonObject.body));

        const serviceResponse: ServiceResponse = jsonBodyObject as ServiceResponse;
        return serviceResponse;

        /*const request: Request = {
            method: jsonBodyObject.method,
            url: jsonBodyObject.url,
            body: jsonBodyObject.body,
            headers: jsonBodyObject.headers,
            params: jsonBodyObject.params,
        };

        return request;*/
        //Todo: (sivad): check unhappy
        /*TelemetryService.trackTrace("App Data Fetched", SeverityLevel.Information, {        appData: JSON.stringify(appDataList),    }); */
    } catch (e) {
        const error = e as Error;
        console.error(error.message);
        return undefined;
    }
};

//----------------------------------------------------------------
//entity API Operations (environment, workspace, variable)
//----------------------------------------------------------------
//Scenario : to get entity like env, workspace data
export const getWorkspaceEnvironments = async (getEntityRequest: EntityRequest): Promise<Environment[] | undefined> => {
    const requestType = RequestType.POST;
    const url = `${appConfig().apixApiBase}/V1/environment`;
    const body = JSON.stringify(getEntityRequest);

    try {
        const httpResponse = executeHTTPRequest(requestType, url, undefined, undefined, body);
        const jsonData = JSON.stringify((await httpResponse).responseBody);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const jsonObject = JSON.parse(jsonData);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
        const jsonBodyObject = JSON.parse(JSON.stringify(jsonObject.body));
        //console.log(JSON.stringify(jsonObject.body));
        const envList: Environment[] = jsonBodyObject as Environment[];

        return envList;
        //Todo: (sivad): check unhappy
        /*TelemetryService.trackTrace("App Data Fetched", SeverityLevel.Information, {        appData: JSON.stringify(appDataList),    }); */
    } catch (e) {
        const error = e as Error;
        console.error(error.message);
        return undefined;
    }
};

export const getUserWorkspaces = async (getEntityRequest: EntityRequest): Promise<Workspace[] | undefined> => {
    const requestType = RequestType.POST;
    const url = `${appConfig().apixApiBase}/V1/workspace`;
    const body = JSON.stringify(getEntityRequest);

    try {
        const httpResponse = executeHTTPRequest(requestType, url, undefined, undefined, body);
        const jsonData = JSON.stringify((await httpResponse).responseBody);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const jsonObject = JSON.parse(jsonData);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
        const jsonBodyObject = JSON.parse(JSON.stringify(jsonObject.body));
        const workspaceList: Workspace[] = jsonBodyObject as Workspace[];

        return workspaceList;
        //Todo: (sivad): check unhappy
        /*TelemetryService.trackTrace("App Data Fetched", SeverityLevel.Information, {        appData: JSON.stringify(appDataList),    }); */
    } catch (e) {
        const error = e as Error;
        console.error(error.message);
        return undefined;
    }
};

//----------------------------------------------------------------
//proxy the target Request thru WebInvoke API Operations
//----------------------------------------------------------------
//scenario : SendRequest To Targeted url with all the payload

//call patterns
//export const webRequestInvoke = async (webInvokeRequest: WebInvokeRequest): Promise<HTTPResponse | undefined> => {
//export async function MakeFetchRequest(requestObj: TargetRequest): Promise<MakeFetchResponse> {
export async function webRequestInvoke(webInvokeRequest: WebInvokeRequest, cv: string): Promise<HTTPResponse> {
    const requestType = RequestType.POST;
    const url = `${appConfig().apixApiBase}/V1/webrequest/invoke`;
    const body = JSON.stringify(webInvokeRequest);
    //todo : hack code : passing the environmentid thru correlationId to have env based logic in httprequest
    const eid = String(webInvokeRequest.environmentId);
    const httpResponse = executeHTTPRequest(requestType, url, cv, eid, body);

    //const jsonData = JSON.stringify((await httpResponse).responseBody);

    //const jsonObject = JSON.parse(jsonData);

    //const jsonBodyObject = JSON.parse(JSON.stringify(jsonObject.body));
    // console.log(JSON.stringify(jsonObject.body));
    //const envList: Environment[] = jsonBodyObject as Environment[];

    return httpResponse;
    //Todo: (sivad): check unhappy
    /*TelemetryService.trackTrace("App Data Fetched", SeverityLevel.Information, {        appData: JSON.stringify(appDataList),    }); */
}
